import './App.css';
// import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
// import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useState, useEffect } from 'react';
import pdfToText from "react-pdftotext";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_API_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID
// };

const firebaseConfig = {
  apiKey: "AIzaSyCy9nTcvxxvuwnINliyj1WDSR1z6ahHWgk",
  authDomain: "pdf-converter-a51e0.firebaseapp.com",
  projectId: "pdf-converter-a51e0",
  storageBucket: "pdf-converter-a51e0.firebasestorage.app",
  messagingSenderId: "871511285544",
  appId: "1:871511285544:web:2d894680a046e4d2d7fa1d",
  measurementId: "G-JJ4VD1QE0H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  // https://www.codefrontend.com/file-upload-reactjs/
  const [file, setFile] = useState([]);
  const [fileText, setFileText] = useState('');

  useEffect(() => {
    logEvent(analytics, 'App_screen_visit');
  }, [])

  function handleTipUs() {
    console.log('TIP US!!');
  }

  function extractText(file) {
    pdfToText(file)
      .then((text) => {
        setFileText(text);
      })
      .catch((error) => console.error("Failed to extract text from pdf"));
  }


  const handleFileChange = (e) => {
    if (e.target.files) {
      // TODO update if we can send multiple
      setFile(e.target.files[0]);
      extractText(e.target.files[0])
    }
  };

  function handleWordDoc() {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun(fileText),
              ],
            }),
          ],
        },
      ],
    });

    // Used to export the file into a .docx file
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, "MyWordDoc.docx");
    });
  }

  function handleTextFile() {
    const element = document.createElement("a");
    const tempFile = new Blob([fileText], { type: 'text/plain' });
    element.href = URL.createObjectURL(tempFile);
    element.download = "MyPDFText.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  // const LoginButton = () => {
  //   const { loginWithRedirect } = useAuth0();
  //   return <Button variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }} onClick={() => loginWithRedirect()}>Log In</Button>;
  // };

  // const LogoutButton = () => {
  //   const { logout } = useAuth0();
  //   return (
  //     <Button variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
  //       Log Out
  //     </Button>
  //   );
  // };

  // const Profile = () => {
  //   const { user, isAuthenticated } = useAuth0();
  //   if (!isAuthenticated) {
  //     return (
  //       <Stack direction="row" spacing={3}>
  //         <Avatar alt="User avatar" sx={{ bgcolor: '#0A2239' }} />
  //         <LoginButton />
  //       </Stack>
  //     )
  //   }

  //   return (
  //     isAuthenticated && (
  //       <Stack direction="row" spacing={3}>
  //         <Stack direction="row" spacing={3}>
  //           <Avatar alt={user.name} src={user.picture} />
  //           <p style={{ color: '#0A2239' }}>{user.email}</p>
  //         </Stack>
  //         <LogoutButton />
  //       </Stack>
  //     )
  //   );
  // };

  return (
    <div className="App">
      <header className="App-header">
        {/* <div style={{ right: 20, position: 'absolute', top: 20 }}>
          <Profile />
        </div> */}


        <Stack direction="row" spacing={3}>
          <h2 style={{ color: '#0A2239' }}> Convert a PDF into a Text File or Word Doc</h2>
          <Button target="_blank" href="https://buymeacoffee.com/marea" variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }} onClick={handleTipUs}>Tip Us!</Button>
        </Stack>

        <p style={{ color: '#0A2239', fontSize: 18 }}>We value your privacy, so we do not store any of your data — not even locally in the browser.</p>

        <p style={{ color: '#0A2239' }}>Step 1: Upload your PDF document (one at a time) below</p>

        <Stack direction="row" spacing={3}>
          {/* https://frontendshape.com/post/how-to-use-file-upload-in-react-mui-5 */}
          <Button variant="contained" component="label"
            sx={{ backgroundColor: '#0A2239', margin: 10 }}>
            Upload PDF
            {/* https://stackoverflow.com/questions/1548194/how-to-get-the-htmls-input-element-of-file-type-to-only-accept-pdf-files */}
            <input onChange={handleFileChange} hidden accept="image/*" type="file" />
          </Button>
        </Stack>

        <p style={{ color: '#0A2239' }}>Step 2: Select your preferred format</p>

        <Stack direction="row" spacing={3}>
          <Button onClick={handleWordDoc} disabled={file?.length === 0} variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }}>Word Doc</Button>
          <Button onClick={handleTextFile} disabled={file?.length === 0} variant="contained" sx={{ backgroundColor: '#0A2239', margin: 10 }}>Plain Text</Button>
        </Stack>

        <p style={{ color: '#0A2239' }}>Step 3: Your file will download to the browser</p>

        <a style={{ fontSize: 10, color: '#0A2239', paddingTop: 100 }} href="https://www.flaticon.com/free-icons/pdf" title="pdf icons">PDF icon created by kliwir art - Flaticon</a>
      </header>
    </div >
  );
}

export default App;
